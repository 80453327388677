<template>
  <div v-if="loaded" class="start-a-business">
    <progress-steps
      :subtitle="subtitle"
      :progress-status="progressStatus"
    />

    <div class="main-container">
      <h2 class="sl__h2 container-header">
        There is already a company on your account by that name.
      </h2>
      <div class="container-body">
        <div class="company-container existing-company">
          <div class="company-card">
            <div class="card-header">
              <h6 class="sl__h6">Existing Company</h6>
            </div>
            <div class="card-body">
              <div class="name-card">
                <p class="sl__p">
                  {{ similarCompany?.name }}
                </p>
              </div>
              <button
                class="continue-btn btn btn-primary"
                aria-label="continue button"
                @click="goToExistingCompany"
              >
                Go to company
              </button>
            </div>
          </div>
          <p class="sl__p change-name-email">
            <small class="sl__small">
              Want to change this company name?
            </small>
          </p>
          <p class="sl__p change-name-email">
            <small class="sl__small">
              Email us at <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
            </small>
          </p>
        </div>
        <div class="company-container new-company">
          <div class="company-card">
            <div class="card-header">
              <h6 class="sl__h6">New Company</h6>
            </div>
            <div class="card-body">
              <div class="name-card">
                <p class="sl__p company-name">
                  {{ fullCompanyName }}
                </p>
              </div>
              <button
                class="continue-btn btn btn-outline-primary secondary-btn"
                aria-label="continue button"
                @click="next"
              >
                Continue with this name
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button-container
      :previous-button-label="previousButtonLabel"
      :show-previous-button="showPreviousButton"
      :show-next-button="showNextButton"
      @next="next"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SimilarCompanyName',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ProgressSteps: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressSteps'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  data () {
    return {
      subtitle: 'company name',
      progressStatus: 70,
      previousButtonLabel: 'Start over with a new name',
      showNextButton: false,
      showPreviousButton: true,
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'similarCompany',
      'fullCompanyName',
    ]),
    ...mapGetters('stageline', [
      'companyStagelineRoute',
    ]),
    ...mapGetters('website', [
      'website',
    ]),
    supportEmail() {
      return this.website && this.website.emails ? this.website.emails[0] : null
    },
  },
  async mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setCurrentSlide',
    ]),
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    async goToExistingCompany() {
      const { id, stageline } = this.similarCompany
      const stagelineRoute = this.companyStagelineRoute
      const lastVisitedSlide = stageline?.last_visited_slide

      await this.setCurrentCompany({
        id,
        force: true,
      })

      const routeName = !stagelineRoute && !lastVisitedSlide
        ? 'stageline-v2-decision-tree'
        : 'stageline-v2-start'

      await this.$router.push({
        name: routeName,
        params: { companyId: id },
      })

      if (routeName === 'stageline-v2-decision-tree') {
        this.loaded = true
      }
    },
    next() {
      this.setCurrentSlide('ConfirmCompany')
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__h2 {
  text-align: left;
}

.start-a-business {

  .main-container {

    .container-body {
      @include sl__flex(row, space-between, flex-start);
      flex-wrap: wrap;

      .company-container {
        width: min-content;

        .company-card {
          border-width: 1px;
          border-style: solid;
          border-radius: 0.3125rem;
          width: 20rem;

          .card-header {
            width: 100%;
            border-radius: 0.1875rem 0.1875rem 0 0;
            height: 2.75rem;
            padding: 0.6rem;
            @include sl__flex(row, center, center);
          }

          .card-body {
            @include sl__flex(column, space-between, center);
            padding: 1rem 2.5rem 1.5rem 2.5rem;
            min-height: 10.5rem;

            .name-card {
              @include sl__flex(row, center, center);
              min-height: 5rem;

              .company-name {
                font-weight: 600;
              }
            }

            button {
              width: 100%;
              padding: 0.5rem 1rem;

              &.secondary-btn {
                color: $sl__teal1;
                font-weight: 700;
                border-color: $sl__teal1;
                border-width: 0.125em;

                &:hover {
                  background-color: $sl__teal1-shade1 !important;
                }
              }
            }
          }
        }

        &.existing-company {

          .company-card {
            border-color: $sl__teal1;
            margin-bottom: 1.0em;

            .card-header {
              background-color: $sl__teal1;
              color: white;
            }
          }
          .change-name-email {
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
          }
        }

        &.new-company {

          .company-card {
            border-color: rgba(0, 0, 0, 0.15);

            .card-header {
              background-color: rgba(0, 0, 0, 0.075);
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .start-a-business {

    .main-container {

      .container-body {
        justify-content: center !important;
        row-gap: 2.0em !important;
      }
    }
  }
}
</style>
