var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "start-a-business" },
        [
          _c("progress-steps", {
            attrs: {
              subtitle: _vm.subtitle,
              "progress-status": _vm.progressStatus,
            },
          }),
          _c("div", { staticClass: "main-container" }, [
            _c("h2", { staticClass: "sl__h2 container-header" }, [
              _vm._v(
                "\n      There is already a company on your account by that name.\n    "
              ),
            ]),
            _c("div", { staticClass: "container-body" }, [
              _c("div", { staticClass: "company-container existing-company" }, [
                _c("div", { staticClass: "company-card" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "name-card" }, [
                      _c("p", { staticClass: "sl__p" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.similarCompany?.name) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "continue-btn btn btn-primary",
                        attrs: { "aria-label": "continue button" },
                        on: { click: _vm.goToExistingCompany },
                      },
                      [_vm._v("\n              Go to company\n            ")]
                    ),
                  ]),
                ]),
                _vm._m(1),
                _c("p", { staticClass: "sl__p change-name-email" }, [
                  _c("small", { staticClass: "sl__small" }, [
                    _vm._v("\n            Email us at "),
                    _c("a", { attrs: { href: `mailto:${_vm.supportEmail}` } }, [
                      _vm._v(_vm._s(_vm.supportEmail)),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "company-container new-company" }, [
                _c("div", { staticClass: "company-card" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "name-card" }, [
                      _c("p", { staticClass: "sl__p company-name" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.fullCompanyName) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass:
                          "continue-btn btn btn-outline-primary secondary-btn",
                        attrs: { "aria-label": "continue button" },
                        on: { click: _vm.next },
                      },
                      [
                        _vm._v(
                          "\n              Continue with this name\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("button-container", {
            attrs: {
              "previous-button-label": _vm.previousButtonLabel,
              "show-previous-button": _vm.showPreviousButton,
              "show-next-button": _vm.showNextButton,
            },
            on: { next: _vm.next },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "sl__h6" }, [_vm._v("Existing Company")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "sl__p change-name-email" }, [
      _c("small", { staticClass: "sl__small" }, [
        _vm._v("\n            Want to change this company name?\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "sl__h6" }, [_vm._v("New Company")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }